import { Routes, RouterModule } from "@angular/router";

// Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../pages/full-layout-page/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },

  {
    path: "eventparticipants",
    loadChildren: () =>
      import("../../pages/full-layout-page/contact/contacts.module").then(
        (m) => m.ContactsModule
      ),
  },
  {
    path: "contacts",
    loadChildren: () =>
      import("../../pages/full-layout-page/contact/contacts.module").then(
        (m) => m.ContactsModule
      ),
  },
  {
    path: "unauthorized",
    loadChildren: () =>
      import(
        "../../pages/full-layout-page/unauthorized/unauthorized.module"
      ).then((m) => m.UnAuthorizedModule),
  },
  {
    path: "customers",
    loadChildren: () =>
      import(
        "../../pages/full-layout-page/customers/customers.module"
      ).then((m) => m.CustomersModule),
  },
  {
    path: "professionalmembers",
    loadChildren: () =>
      import(
        "../../pages/full-layout-page/professionalmembers/professionalmembers.module"
      ).then((m) => m.ProfessionalMembersModule),
  },
  {
    path: "downloadpass",
    loadChildren: () =>
      import(
        "../../pages/full-layout-page/downloadpass/downloadpass.module"
      ).then((m) => m.DownloadpassModule),
  },
  {
    path: "users",
    loadChildren: () =>
      import(
        "../../pages/full-layout-page/users/users.module"
      ).then((m) => m.UsersModule),
  },
];
