import { Injectable } from "@angular/core";
import { DataService } from "./data.service";
import { RouteHelperService } from "./route.helper.service";
import { map } from 'rxjs/operators';
import { HttpClient } from "@angular/common/http";


@Injectable()
export class CustomerService {

    constructor(private routeHelperService: RouteHelperService,
        private dataService: DataService, private httpClient: HttpClient) { }

    getallData(refresh: boolean, requestData: any) {
        return this.httpClient.get<any>("assets/markdata/kliky-user.json").pipe(
            map((response) => {
                return response;
            }));
        // const data = this.dataService.getHttpParams(requestData);
        // return this.dataService.getDataWithParams(this.routeHelperService.NEWSERVICES.getallDataRoute(), data, refresh);
    }
    getRegistrationDetails(search) {
        return this.dataService.post(this.routeHelperService.EVENTREG.getRegDetails(), search).pipe(map((response) => {
            this.dataService.clearRouteCache(this.routeHelperService.NEWSERVICES.getallDataRoute());
            return response;
        }));
    }

    getallProfessionalMembersData(refresh: boolean, requestData: any) {
        return this.httpClient.get<any>("assets/markdata/kliky-professional.json").pipe(
            map((response) => {
                return response;
            }));
        // const data = this.dataService.getHttpParams(requestData);
        // return this.dataService.getDataWithParams(this.routeHelperService.NEWSERVICES.getallDataRoute(), data, refresh);
    }
    getSubscriptionsData(refresh: boolean, requestData: any){
        return this.httpClient.get<any>("assets/markdata/kliky-userSubscriptions.json").pipe(
            map((response) => {
                return response;
            }));
    }

    getById(id: number) {
        return this.dataService.getRecord(this.routeHelperService.NEWSERVICES.getByIDRoute(id));
    }

    save(data) {
        return this.dataService.post(this.routeHelperService.NEWSERVICES.saveRoute(), data).pipe(map((response) => {
            this.dataService.clearRouteCache(this.routeHelperService.NEWSERVICES.getallDataRoute());
            return response;
        }));
    }

    delete(id: number) {
        return this.dataService.delete(this.routeHelperService.NEWSERVICES.deleteRoute(id));
    }
}
