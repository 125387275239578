<div

  [class]="'app-sidebar sidebar-shadow '"

  (mouseover)="sidebarHover()"

  (mouseout)="sidebarHover()"

>

  <div class="app-header__logo">

    <div class="logo-src" style="height: 60px;margin-top: 15px;"></div>

    <div class="header__pane ml-auto">

      <button

        type="button"

        class="hamburger close-sidebar-btn hamburger--elastic"

        [ngClass]="{ 'is-active': globals.toggleSidebar }"

        (click)="toggleSidebar()"

      >

        <span class="hamburger-box">

          <span class="hamburger-inner"></span>

        </span>

      </button>

    </div>

  </div>



  <div class="event-div" *ngIf="!globals.toggleSidebar">

    <h1>

      <span> {{ eventData?.name }} </span>

    </h1>

  </div>



  <div class="scroll-area-lg">

    <perfect-scrollbar

      ng-reflect-auto-propagation="true"

      class="ps-show-limits"

    >

      <div class="app-sidebar-content">

        <div class="v-sidebar-menu vsm-default">

          <div class="vsm-list">

            <div class="vsm-header"></div>

            <div class="vsm-item">

              <a

                [routerLink]="['/dashboard']"

                queryParamsHandling="merge"

                routerLinkActive="active-item"

                class="vsm-link"

              >

              

                <span class="vsm-title"> Dashboard</span>

              </a>

            </div>



            <div class="vsm-header"></div>

            <!-- <div class="vsm-item">

              <a

                [routerLink]="['/users']"

                queryParamsHandling="merge"

                routerLinkActive="active-item"

                class="vsm-link"

              >

              

                <span class="vsm-title">User Management</span>

              </a>

            </div> -->

            <div class="vsm-item">

              <a

                [routerLink]="['/customers']"

                queryParamsHandling="merge"

                routerLinkActive="active-item"

                class="vsm-link"

              >

                <span class="vsm-title">Registration Details</span>

              </a>

            </div>

            <div class="vsm-item" style="display: none;">

              <a

                [routerLink]="['/downloadpass']"

                queryParamsHandling="merge"

                routerLinkActive="active-item"

                class="vsm-link"

              >

                <span class="vsm-title">Download Entry Pass</span>

              </a>

            </div>

          

          </div>

        </div>

      </div>

    </perfect-scrollbar>

  </div>

</div>

