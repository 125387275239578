<div class="app-footer">
  <div class="app-footer__inner">
    <div class="app-footer-left">
      <span class="pl-2">Copyright @AIDC2024</span>
    </div>
    <div class="app-footer-right">
        <!-- <span *ngIf="showEnvironment" class="d-none d-sm-inline-block mr-1"> End Point - {{apiBaseUrl}}</span> -->
        <!--<span class="d-none d-sm-inline-block"> <strong>V{{currentApplicationVersion}}</strong> </span>-->
    </div>
  </div>
</div>
